.vertical-row {
  height: 900px;
}

.skill-mobile, .skill-tablet {
  transform: rotateZ(90deg);
  color: white;
  position: relative;
  top: 40px;
  left: 0;
  right: 0;
}