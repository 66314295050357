.navigator {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 155px;
  right: 50px;
  .location {
    background-color: #989898;
    color: white;
    padding: 5px 10px;
    margin-top: 10px;
    text-align: center;
    width: 30px;
    position: relative;
    &:not(.selected) {
      right: -70px;
    }
    &:hover {
      &:not(.selected) {
        transition: 150ms ease-in-out;
        width: 60px;
        right: -40px;
      }
    }
    &.selected {
      width: 138px;
      background-color: black;
      p {
        font-size: 20px;
        margin: 0;
        font-weight: bold;
      }
    }
  }
}