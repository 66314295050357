.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

* {
  font-family: Roboto-Black, Roboto, serif;
}

.btn.disabled {
  filter: grayscale(1);
  cursor: not-allowed;
}
.btn.disabled span {
  color: #5b5b5b !important;
}

.Toastify__toast {
  border-radius: 4px !important;
}

.Toastify__close-button {
  display: none;
}

.messages {
  position: fixed;
  top: 150px;
  right: 10px;
  color: white;
  font-weight: 500;
  z-index: 10000;
}
.messages .message {
  padding: 20px 40px;
  background-color: #75d200;
  border-radius: 4px;
}
.messages .message.success {
  background-color: #75d200;
}
.messages .message.error {
  background-color: #cc0000;
}
.messages .message p {
  margin: 0;
}

.intro-widget {
  height: 55vh;
}
.intro-widget .content {
  max-width: 500px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20vh;
  position: absolute;
}
.intro-widget .content h1 {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 64px;
  margin: 0;
}
.intro-widget .content p {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin: 0 auto;
  color: #4A4A4A;
}

.stage .sub-header {
  margin-top: 200px;
}

.sub-header h1 {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 50px;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.67);
  z-index: 1000;
}
.header .logo {
  padding: 20px;
  display: inline-block;
  float: left;
  cursor: pointer;
}
.header .logo h1 {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}
.header .logo p {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  float: right;
}

.previous-work-widget .card {
  background: #F0F0F0;
  border-radius: 15px;
  padding: 20px;
  border: 0;
  height: 360px;
  position: relative;
  margin-bottom: 20px;
  text-align: left;
}
.previous-work-widget .card h1 {
  margin-top: 80px;
  font-size: 34px;
  font-weight: bold;
}
.previous-work-widget .card p {
  font-size: 16px;
}
.previous-work-widget .card .btn {
  background: #FFFFFF;
  border-radius: 100px;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 20px;
}
.previous-work-widget .card .btn span {
  color: #313131;
  font-weight: bold;
  font-family: Roboto-Black, Roboto, serif;
}
.previous-work-widget .card.glide-student {
  background-image: linear-gradient(-180deg, #B4EC51 0%, #429321 100%);
  color: white;
}
.previous-work-widget .card.glide-student .btn span {
  color: #7ED321;
}

.contact-widget .input-group {
  position: relative;
  margin-bottom: 10px;
}
.contact-widget .input-group input {
  background: #F0F0F0;
  border-radius: 7px;
  border: 0;
  padding: 10px;
  padding-top: 25px;
  width: 100%;
  font-weight: bold;
}
.contact-widget .input-group input:hover, .contact-widget .input-group input:focus, .contact-widget .input-group input:active {
  outline: none;
  box-shadow: none;
}
.contact-widget .input-group input:focus {
  background-color: #e0e0e0;
}
.contact-widget .input-group label {
  position: absolute;
  left: 10px;
  top: 5px;
}
.contact-widget .btn {
  width: 100%;
  padding: 17px;
  background: black;
  color: white;
  border-radius: 7px;
  font-weight: bold;
  transition: 150ms ease-in-out;
}
.contact-widget .btn:hover, .contact-widget .btn:focus, .contact-widget .btn:active {
  outline: none;
  box-shadow: none;
  background-color: #434343;
}

.thank-you-widget {
  padding: 45vh 0;
  overflow: hidden;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 55px;
}
.thank-you-widget .confetti {
  position: relative;
  left: 0;
  right: 0;
  top: 440px;
  margin: 0 auto;
  width: 1px;
  height: 1px;
}
.thank-you-widget h3 {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 64px;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: 200ms padding, background-color ease-in-out;
  display: inline-block;
}
.thank-you-widget h3:hover {
  background-color: #80808026;
  border-radius: 20px;
  margin: 0;
  display: inline-block;
  padding: 10px;
}
.thank-you-widget p {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  width: auto;
  margin: 0 auto;
  color: #4A4A4A;
  cursor: default;
}
.thank-you-widget .footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.footer {
  position: fixed;
}
.footer .logo {
  padding: 20px;
  display: inline-block;
  float: left;
}
.footer .logo h1 {
  font-family: Roboto-Black, Roboto, serif;
  font-weight: bold;
  font-size: 28px;
  margin: 0;
}
.footer .logo p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  float: right;
}

.input-group {
  position: relative;
  margin-bottom: 10px;
}
.input-group input {
  background: #F0F0F0;
  border-radius: 7px;
  border: 0;
  padding: 10px;
  padding-top: 25px;
  width: 100%;
  font-weight: bold;
}
.input-group input:hover, .input-group input:focus, .input-group input:active {
  outline: none;
  box-shadow: none;
}
.input-group input:focus {
  background-color: #e0e0e0 !important;
}
.input-group input:disabled {
  cursor: not-allowed;
  background-color: #cdcdcd !important;
}
.input-group label {
  position: absolute;
  left: 10px;
  top: 5px;
}

.hamburger-menu {
  width: 40px;
  display: block;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 20px;
  transition: 500ms ease-in-out;
}
.hamburger-menu:hover {
  background-color: #00000026;
  border-radius: 50px;
}

.mobile-menu {
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  margin: auto;
  top: 100%;
  height: 100vh;
  padding: 0;
  background-color: white;
}
.mobile-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile-menu ul li {
  width: 100%;
}
.mobile-menu ul li a, .mobile-menu ul li .navigation-button {
  float: none;
  width: 100%;
  padding: 20px;
  margin: 0;
}
.mobile-menu ul li a:after, .mobile-menu ul li .navigation-button:after {
  content: none;
}
.mobile-menu ul li a.active, .mobile-menu ul li .navigation-button.active {
  background-color: black;
  color: white;
}
.mobile-menu ul li a:not(.active):hover, .mobile-menu ul li .navigation-button:not(.active):hover {
  background-color: #fafafa;
}

.impersonation {
  width: 350px;
  padding: 20px;
  position: fixed;
  bottom: 100px;
  background-color: #fafafa;
  border-radius: 10px;
  left: -20px;
  z-index: 10000;
}
.impersonation p {
  margin: 0;
}
.impersonation button {
  margin: 5px 0;
  width: 100%;
}

.navigation-button {
  cursor: pointer;
}

.rain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#page.not-found {
  padding: 40vh 20px;
}
#page.not-found h1 {
  font-size: 68px;
  font-weight: 900;
}

@media (min-width: 576px) {
  .header .logo {
    padding: 20px;
  }
  .header .logo h1 {
    font-size: 28px;
  }
  .header .logo p {
    font-size: 18px;
  }

  .thank-you-widget p {
    width: 40%;
  }
}
.user-account {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  background-color: rgba(225, 241, 241, 0.81);
  border-radius: 4px;
  margin-top: 26px;
  margin-left: 20px;
  float: right;
  margin-right: 20px;
  position: relative;
  padding-right: 35px;
  padding-left: 20px;
  min-width: 200px;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.user-account span {
  color: #36b5b5;
}
.user-account i {
  color: #bde7e9;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  height: 16px;
  transition: 250ms ease-in-out;
  border-radius: 4px;
  width: 10%;
}
.user-account i:hover {
  color: #36b5b5;
  background-color: #e7f4f4;
  padding: 10px 0;
  height: 100%;
  width: 100%;
  right: 0px;
}
.user-account .list {
  color: #36b5b5;
  position: absolute;
  top: 40px;
  background-color: #e7f4f4;
  width: 100%;
  left: 0;
  border-radius: 4px;
  opacity: 1;
  transition: 250ms ease-in-out;
}
.user-account .list .item {
  padding: 10px;
  margin: 2px;
  border-radius: 4px;
  transition: 250ms ease-in-out;
}
.user-account .list .item p {
  margin: 0;
}
.user-account .list .item:hover {
  background-color: #ccebed;
  color: #36b5b5;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding {
  padding: 0;
}

@media (min-width: 768px) {
  #page .portal-header h1 {
    font-size: 28px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

