.github-widget {
  .container {
    position: relative;
    .input-group {
      transition: 200ms ease-in-out;
      background: #E8E8E8;
      border-radius: 12px;
      width: 35px;
      height: 35px;
      border: 0;
      position: absolute;
      right: 20px;
      top: -50px;
      color: black;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 13px;
        cursor: pointer;
      }
      input {
        border: none;
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
        height: 100%;
        background: #E8E8E8;
        padding: 0;
        display: none;
      }
      &.open {
        width: 230px;
        img {
          right: auto;
          left: 10px;
        }
        input {
          margin-left: 30px;
          margin-right: 10px;
          display: block;
          font-weight: bold;
        }
      }
    }

    .left-button {
      position: absolute;
      top: 0;
      left: -40px;
      bottom: 0;
      margin: auto;
    }
    
    .right-button {
      position: absolute;
      top: 0;
      right: -40px;
      bottom: 0;
      margin: auto;
    }

    .left-button, .right-button {
      cursor: pointer;
      opacity: 0.4;
      transition: 200ms ease-in-out;
      &:hover {
        opacity: 1;
      }
    }

    .not-found {
      padding: 100px 0;
      width: 100%;
    }

    .tags {
      margin-top: 10px;
      p {
        text-align: left;
        margin-bottom: 6px;
        margin-left: 10px;
        font-weight: 900;
        font-size: 12px;
        color: #4b4b4b;
      }
      .tag {
        background-color: #e8e8e8;
        display: inline-block;
        padding: 10px 25px;
        margin-top: 5px;
        border-radius: 10px;
        float: left;
        transition: 250ms ease-in-out;

        &:nth-child(n+2) {
          margin-left: 5px;
        }

        p {
          margin: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: #d7d7d7;
        }
      }
    }
    .repository {
      float: left;
      width: 100%;
      padding: 20px;
      height: 250px;
      background-color: black;
      border-radius: 15px;
      text-align: left;
      position: relative;
      transition: 150ms linear;
      margin-bottom: 20px;
      h1 {
        color: white;
        font-size: 24px;
        font-weight: bold;
      }
      p {
        color: white;
        opacity: 0.55;
      }
      .btn {
        background-color: #fff;
        border-radius: 100px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
      &.foreground-true {
        opacity: 0.4;
        filter: blur(10px);
      }
      &.javascript {
        background-image: linear-gradient(-180deg, #F5515F 0%, #9F041B 100%);
        .btn {
          color: #9F041B;
        }
      }
      &.css {
        background-image: linear-gradient(-180deg, #FAD961 0%, #F76B1C 100%);
        .btn {
          color: #F76B1C;
        }
      }
      &.php {
        background-image: linear-gradient(-134deg, #C86DD7 0%, #3023AE 100%);
        .btn {
          color: #C86DD7;
        }
      }
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .github-widget {
    .container {
      .repository {
        margin-bottom: auto;
      }
    }
  }
}
