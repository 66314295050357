.skill {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  h1 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 20px;
    font-weight: bold;
    font-size: 54px;
    font-family: Roboto-Black, Roboto, serif;
  }
}